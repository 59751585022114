<div class="body">
    <!-- <mat-toolbar>
        <span>My Application</span>
    </mat-toolbar> -->
    <div class="container">
        <div fxLayout="row" class="myToolbar" fxLayoutAlign="space-between center" fxLayoutGap="gappx">
            <!-- <h2>W Live</h2> -->
            <img width="50" height="50" src="assets/images/final.png" alt="IOS App Store" />
        </div>
        <div class="hldr-home row" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="gappx">
            <div fxLayout="column" class="col-xl-6 col-lg-6 " fxLayoutAlign="center start" fxLayoutGap="gappx">
                <p class="Mtitle">Wlive App</p>
                <p class="description">W Live is a Group Voice Talking and Entertaining Community. Voice Chat and flex
                    you daily activity with people from nearby or all over the world.
                    <br><br>
                    Finding and meeting a friends is never easy.
                    Choose group voice rooms from live rooms daily, filter rooms by Countries or Topics. 50+ Countries
                    have already been covered, while tons of topics are available to choose from.
                </p>
                <br>
                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx">
                    <img class="col-xl-4 col-lg-4 col-sm-4 col-md-4 col-xs-2 col-6 appstore"
                        src="assets/images/ios-app-store.png" alt="IOS App Store
                    " (click)="openIOS('https://apps.apple.com/qa/app/w-live/id1549284172')" />
                    <img class="col-xl-4 col-lg-4 col-sm-4 col-md-4 col-xs-2 col-6 playstore"
                        src="assets/images/google-play-store.png" alt="Google Play Store"
                        (click)="openIOS('https://play.google.com/store/apps/details?id=com.wlive.app&hl=en&gl=US')" />

                </div>



            </div>


            <div *ngIf="isMobile == true" fxLayout="column" class="col-xl-6 col-lg-6 " fxLayoutAlign="center center"
                fxLayoutGap="gappx">
                <div class="hldr-carousel">
                    <carousel class="carousel" [dots]="true" [loop]="true" [images]="images" [autoplay]="true"
                        [arrows]="true" [cellsToShow]="1" [arrowsOutside]="true">
                    </carousel>
                </div>
            </div>

            <div *ngIf="isMobile == false" fxLayout="column" class="col-xl-6 col-lg-6 " fxLayoutAlign="center center"
                fxLayoutGap="gappx">
                <br><br>
                <div class="hldr-carousel">
                    <carousel class="carousel" [dots]="true" [loop]="true" [images]="images" [autoplay]="true"
                        [arrows]="false" [cellsToShow]="1" [arrowsOutside]="true">
                    </carousel>
                </div>
                <br><br>
            </div>

        </div>
    </div>
</div>
<!-- <footer fxLayoutAlign="start start">
    <div class="container">
        <div class="row">
            <div class="col-lg mbotton">
                <ul>
                    <li class="title"><strong>Contact Us</strong></li>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="gappx">
                        <span class="material-icons">email</span>
                        <li>operations@wbluemall.com</li>
                    </div>


                </ul>
            </div>
            <div class="col-lg mbotton">
                <ul>
                    <li class="title"><strong>Know About Us</strong></li>
                    <li class="link" [routerLink]="['/policy']"><a>Privacy Policy</a></li>
                    <li class="link" [routerLink]="['/faq/hot']"><a>FAQs</a></li>
                </ul>
            </div>
            <div class="col-lg mbotton">
                <ul>
                    <li class="title"><strong>Social Media</strong></li>
                    <li>
                        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="gappx">
                            <i class="fab fa-instagram social align-self-center"></i>
                            <a href="https://www.facebook.com/W-live-apps-108844421646179" target="_blank">
                                <i class="fab fa-facebook-f social align-self-center"></i>
                            </a>
                            <a href="https://twitter.com/Wliveapps" target="_blank">
                                <i class="fab fa-twitter social align-self-center"></i>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-lg mbotton">
                <p class="align-self-center">&copy; 2023 All Rights Reserved. W Live</p>
            </div>
        </div>
    </div>
</footer> -->

<footer fxLayoutAlign="start start">
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg mbotton">

                <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="gappx">
                    <p class="align-self-center">&copy; 2023 All Rights Reserved. W Live</p>
                    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx">
                        <p class="homeBtn" mat-button [routerLink]="['/faq/hot']" [routerLinkActive]="['btn-active']">
                            FAQs</p>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <p class="homeBtn" mat-button [routerLink]="['/policy']" [routerLinkActive]="['btn-active']">
                            Privacy Policy</p>
                    </div>
                </div>
            </div> -->

            <div *ngIf="isMobile == true" class="col-lg mbotton">
                <div class="row">
                    <p class="align-self-center">&copy; 2023 All Rights Reserved. W Live</p>&nbsp;&nbsp;|&nbsp;&nbsp;
                    <p class="homeBtn" mat-button [routerLink]="['/faq/hot']" [routerLinkActive]="['btn-active']">
                        FAQs</p>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <p class="homeBtn" mat-button [routerLink]="['/policy']" [routerLinkActive]="['btn-active']">
                        Privacy Policy</p>
                        <!-- &nbsp;&nbsp;|&nbsp;&nbsp;
                    <p class="homeBtn" mat-button [routerLink]="['/terms']" [routerLinkActive]="['btn-active']">
                        Terms & Conditions</p> -->
                </div>
            </div>

            <div *ngIf="isMobile == false" class="col-lg mbotton2">
                <p class="align-self-center">&copy; 2023 All Rights Reserved. W Live</p>
                <p class="homeBtn" mat-button [routerLink]="['/faq/hot']" [routerLinkActive]="['btn-active']">
                    FAQs</p>
                <p class="homeBtn" mat-button [routerLink]="['/policy']" [routerLinkActive]="['btn-active']">
                    Privacy Policy</p>
                <!-- <p class="homeBtn" mat-button [routerLink]="['/terms']" [routerLinkActive]="['btn-active']">
                    Terms & Conditions</p> -->
            </div>
        </div>
    </div>
</footer>
import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'w-live-website';

  constructor(private meta: Meta, private Webtitle: Title) {}

  ngOnInit(): void {
    // this.Webtitle.setTitle('META TEST');
    // this.meta.addTags([
    //   { name: 'title', content: 'TEST ONLY OKAY?' },
    //   { name: 'description', content: 'TEST ONLY OKAY?' },

    //   { property: 'twitter:card', content: 'summary' },
    //   { property: 'twitter:title', content: 'TEST ONLY OKAY?' },
    //   {
    //     property: 'twitter:description',
    //     content: 'TEEEEEEEEEEEEEEEEEEEEEEEEEEST',
    //   },
    //   {
    //     property: 'twitter:image',
    //     content:
    //       'https://farm6.staticflickr.com/5510/14338202952_93595258ff_z.jpg',
    //   },
    //   { property: 'og:type', content: 'website' },
    //   { property: 'og:site', content: 'DOUBLE YOU LAYV' },
    //   { property: 'og:title', content: 'TEST ONLY OKAY? OG' },
    //   { property: 'og:url', content: 'https://fb.com' },
    //   {
    //     property: 'og:description',
    //     content: 'TEEEEEEEEEEEEEEEEEEEEEEEEEEST OG',
    //   },
    //   {
    //     property: 'og:image',
    //     content:
    //       'https://farm6.staticflickr.com/5510/14338202952_93595258ff_z.jpg',
    //   },
    // ]);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [],
  imports: [CommonModule, MatToolbarModule, HttpClientModule],
  exports: [
    MatToolbarModule,
    FlexLayoutModule,
    IvyCarouselModule,
    MatButtonModule,
    MatDividerModule,
    MatRippleModule,
    MatIconModule,
    MatSnackBarModule,
    TranslateModule
  ],
})
export class SharedModule {}

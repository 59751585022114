import { Component, OnInit, Directive, ElementRef, ViewContainerRef, TemplateRef, Input, HostListener } from '@angular/core';


export enum VIEW{
  MOBILE ,
  TABLET ,
  PC 
}


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  iosStore: 'https://apps.apple.com/qa/app/w-live/id1549284172';
  images = [
    { path: 'assets/images/carousel/1.png' },
    { path: 'assets/images/carousel/2.png' },
    { path: 'assets/images/carousel/3.png' },
    { path: 'assets/images/carousel/4.png' },
    { path: 'assets/images/carousel/1.png' },
    { path: 'assets/images/carousel/2.png' },
    { path: 'assets/images/carousel/3.png' },
    { path: 'assets/images/carousel/4.png' },
    { path: 'assets/images/carousel/1.png' },
    { path: 'assets/images/carousel/2.png' },
    { path: 'assets/images/carousel/3.png' },
    { path: 'assets/images/carousel/4.png' },
    { path: 'assets/images/carousel/1.png' },
    { path: 'assets/images/carousel/2.png' },
    { path: 'assets/images/carousel/3.png' },
    { path: 'assets/images/carousel/4.png' },
  ];

  constructor() {
    // this.dl1(
    //   'https://wlivedevelopment.s3.me-south-1.amazonaws.com/join_room?room_id=NB68EeoYXMn6G7mj4KxPlgw2'
    // );
  }

  public innerWidth: any;

  public isMobile : boolean;

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 480) {
      this.isMobile = false;      
    } else {
      this.isMobile = true;  
    } 
    console.log('Size: '+ this.isMobile + ' ' + this.innerWidth);
    
  }

  openIOS(url: string) {
    window.open(url, '_blank');
  }

  // dl1(url: string) {
  //   window.open(url, '_blank');
  // }

  // dl2() {
  //   const link = document.createElement('a');
  //   link.setAttribute('target', '_blank');
  //   link.setAttribute(
  //     'href',
  //     'https://wlivedevelopment.s3.me-south-1.amazonaws.com/.wellknown/apple-app-site-association'
  //   );
  //   // link.setAttribute('download', `products.csv`);
  //   document.body.appendChild(link);
  //   link.click();
  //   link.remove();
  // }
}
